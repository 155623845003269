import axios from "axios";


export let baseURL = 'https://api.usesuede.com';
if (location.origin.includes("l.usesuede.com:12001")) {
    baseURL = "http://l.usesuede.com:12000";
}

const axiosInstance = axios.create({
    baseURL,
    withCredentials: true,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
});

const Api = () => {
    return axiosInstance;
};

export default Api;