import { defineStore } from 'pinia'
import CategoryService from "@/services/category.js";

export const useCategoriesStore = defineStore('categories', {
  state: () => {
    return { 
        categories: [],
        loaded: false,
     }
  },
  getters: {
    getById(state) {
      return (categoryId) => state.categories.find((category) => category.categoryId === categoryId)
    },
  },
  actions: {
    async fetch() {
      try {
        const response = await CategoryService.listCategories();
        this.categories = response.data;
        this.loaded = true;
      } catch (error) {
        console.log(error);
      }
    }
  },
})