import Api from "@/services/api";

const AuthService = {
  login(email, password) {
    return Api().post(`/auth/login`, {
        email: email,
        password: password
    });
  },
  logout() {
    return Api().post(`/auth/logout`);
  },
  getSession() {
    return Api().get(`/auth/session`)
  }
};

export default AuthService;