export default [
    {
      path: '/login',
      name: 'login',
      component: () => import('./views/login.vue'),
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import('./views/dashboard.vue'),
    },
    {
      path: '/accounts',
      name: 'accounts',
      component: () => import('./views/accounts.vue'),
    },
    {
      path: '/categories',
      name: 'categories',
      component: () => import('./views/categories.vue'),
    },
    {
      path: '/transactions',
      name: 'transactions',
      component: () => import('./views/transactions.vue'),
    },
    {
      path: '*',
      redirect: '/dashboard',
    },
  ]