import Api from "@/services/api";

const CategoryService = {
  listCategories() {
    return Api().get(`/categories`);
  },
  createCategory(input) {
    return Api().post(`/categories`, input);
  },
};

export default CategoryService;
