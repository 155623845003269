<template>
    <v-app>
      <v-main>
        <RouterView />
      </v-main>
    </v-app>
</template>

<script>
import AuthService from "@/services/auth.js";
import { useCategoriesStore } from '@/stores/categories.js'

export default {
  name: "App",

  components: {},

  data: () => ({
    isAuthenticated: false,
  }),

  created() {
    this.init();
  },

  setup() {
    const categoriesStore = useCategoriesStore();
    return {
      categoriesStore,
    }
  },

  watch: {
    $route(to) {
      if (to.name != "login") {
        this.populateStores();
      }      
    },
  },

  methods: {
    async init() {
      await this.authenticate();
    },
    async authenticate() {
      if (this.$cookies.get("session") == null) {
        this.redirectToLogin();
        return;
      }

      try {
        await AuthService.getSession();
      } catch (error) {
        console.log(error);
        this.redirectToLogin();
        return;
      }

      if (this.$route.name == "login") {
        this.$router.push("/dashboard");
      }

    },
    redirectToLogin() {
      if (this.$route.name != "login") {
        this.$router.push("/login");
      }
    },  
    populateStores() {
      Promise.all([this.categoriesStore.fetch()]);
    }
  },
};
</script>
